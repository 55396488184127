*,
*:before,
*:after {
  box-sizing: inherit;
}

:root {
  --color-black: #000000;
  --color-white: #ffffff;
}

html {
  height: 100%;
  box-sizing: border-box;
  touch-action: manipulation;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  font-family: var(--font-sans);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary);
  color: var(--text-16);
  overscroll-behavior-x: none;
}

body {
  position: relative;
  min-height: 100%;
  margin: 0;
}

a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.linkImageContainer > div {
  vertical-align: top;
  line-height: 0;
}

input[type='tel'] {
  outline: none;
}

.PhoneInputInput {
  background-color: var(--primary);
}

.NewsletterPopupForm .PhoneInputInput {
  background: #d3d7cf;
}
