@tailwind base;
@import './base.css';
@import './fonts.css';
@import './nprogress.css';
@tailwind components;
@import './components.css';
@import './wysiwyg.css';

@tailwind utilities;

body {
  @apply font-g-regular text-12 leading-14 text-black bg-ecru;
}

[type='search'] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

@layer utilities {
  @variants responsive {
    .wrapper {
      @apply w-full desktop:max-w-desktop px-20 tablet:px-32 mx-auto;
    }
    .heading-1,
    .wysiwyg h1 {
      @apply font-g-regular text-40 leading-52 tracking-spacingTighter laptop:text-60 laptop:leading-64 laptop:tracking-spacingTight font-normal;
    }
    .heading-2,
    .wysiwyg h2 {
      @apply font-g-regular text-32 tracking-none laptop:text-50 leading-44 laptop:leading-60 laptop:tracking-spacingTight font-normal;
    }
    .heading-3,
    .wysiwyg h3 {
      @apply font-g-regular text-24 tracking-none laptop:text-40 leading-36 laptop:leading-52 laptop:tracking-spacingTighter font-normal;
    }
    .heading-4,
    .wysiwyg h4 {
      @apply font-g-regular text-20 laptop:text-32 leading-24 laptop:leading-44 font-normal tracking-none;
    }
    .heading-5,
    .wysiwyg h5 {
      @apply font-g-regular text-24 leading-36 font-normal tracking-none;
    }
    .heading-6,
    .wysiwyg h6 {
      @apply font-g-regular text-20 leading-24 font-normal tracking-none;
    }
    .title {
      @apply font-g-medium text-14 leading-20 uppercase tracking-spacingWide;
    }
    .title-small {
      @apply font-g-medium text-12 leading-16 font-medium uppercase tracking-spacingWide;
    }
    .core-brand {
      @apply font-g-medium text-40 leading-44 laptop:text-50 laptop:leading-52 font-medium tracking-none;
    }
    .wysiwyg p,
    .wysiwyg li,
    .wysiwyg ol,
    .body {
      @apply font-g-regular text-14 leading-24 font-normal tracking-none;
    }
    .body-small {
      @apply font-g-regular text-12 leading-20 font-normal tracking-none;
    }
    .body-small-medium {
      @apply font-g-medium text-12 leading-20 font-normal tracking-none;
    }
    .body-link {
      @apply font-g-regular text-14 leading-20 font-normal underline tracking-none;
    }
    .body-link-small {
      @apply font-g-regular text-12 leading-20 font-normal underline tracking-none;
    }
    .wysiwyg strong,
    .body-bold {
      @apply text-14 leading-24 font-g-bold tracking-spacing;
    }
    .body-bold-small {
      @apply text-12 leading-20 font-g-bold tracking-spacing;
    }

    .no-scroll {
      overflow: hidden;
    }

    .dropdown-shadow {
      box-shadow: 0 10px 12px 0px rgb(0 0 0 / 8%);
    }

    .fixed-component-shadow {
      box-shadow: 0 -2px 12px 0px rgb(0 0 0 / 8%);
    }

    @media (max-width: 768px) {
      .mobile-overflow-width {
        width: calc(100% + 20px);
      }
    }
  }
}

#gorgias-chat-container {
  display: none;
}

.productDescription strong {
  @apply text-14 leading-24 font-g-bold tracking-spacing;
}
