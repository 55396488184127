@font-face {
  font-family: 'GrotesqueBold';
  src: url('/en/fonts/basis-grotesque-bold-pro.otf');
  font-display: swap;
}

@font-face {
  font-family: 'GrotesqueMedium';
  src: url('/en/fonts/basis-grotesque-medium-pro.otf');
  font-display: swap;
}

@font-face {
  font-family: 'GrotesqueRegular';
  src: url('/en/fonts/basis-grotesque-regular-pro.otf');
  font-display: swap;
}

@font-face {
  font-family: 'BradfordLLRegular';
  src: url('/en/fonts/basic-bradfordLL-regular.otf');
  font-display: swap;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');