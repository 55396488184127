// Bed Builder
#builder-container .image__container {
  position: relative;
}

.builder-gallery-wrap {
  margin-bottom: 8px;
  position: relative;
}

.builder-component-gallery {
  margin-bottom: 0;

  .flickity-page-dots {
    bottom: 16px;

    .dot {
      background: white;
      border: 1px solid #999;
    }
    .dot.is-selected {
      background: #aaa;
    }
  }
}

.builder-preview-view-component {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.builder-preview-ar-button {
  position: absolute;
  top: 0;
  right: 12px;

  display: block;
  width: 61px;
  height: 40px;
  background: none;
  padding: 0;
}

.builder-preview-ar-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 48px 16px;
  z-index: 5001;
  background-color: #434238;
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: scroll;

  font-size: 16px;

  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 400px) {
    font-size: 12px;
  }
}

.builder-preview-ar-popup__close {
  display: inline-block;
  position: absolute;
  top: 4px;
  right: 0;
  font-size: 32px;
  background: none;
}

.builder-preview-ar-popup__icon {
  width: 8em;
  height: 8em;
}

.builder-preview-ar-popup__okay {
  background-color: white;
  color: black;
  margin-bottom: 15px;
}

.builder-selector-component {
  padding-bottom: 16px;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 16px;
}

.builder-selector-component-label {
  text-transform: uppercase;
  color: #999;
  margin: 0 4px;
  font-weight: bold;
  letter-spacing: 1px;
}

.builder-selector-component-group-tabs {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0 0 8px 0;
}

.builder-selector-component-group-tab {
  cursor: pointer;
  text-align: center;
  padding: 8px 2px;
  border: 1px solid transparent;
  border-bottom: 1px solid #e0e0e0;
  font-size: 9px;
  flex: 1 1 0;
  @media (min-width: 375px) {
    font-size: 11px;
  }

  div {
    white-space: nowrap;
  }
}

.builder-selector-component-group-tab-icon {
  position: relative;
}

.builder-selector-component-group-short-label {
  position: absolute;
  bottom: 0;
  right: calc(50% - 28px);
  margin: 0;
  padding: 2px 4px;
  background-color: black;
  color: white;
  line-height: 1;
}

.builder-selector-component-group-tab--selected {
  border: 1px solid #e0e0e0;
  border-bottom: 1px solid transparent;
}

.builder-selector-component-sizes {
  overflow-x: auto;
  overflow-y: visible;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  margin-bottom: 12px;
}

.builder-selector-component-material {
  cursor: pointer;
  text-align: center;
  color: #aaa;

  border-top: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: -1px;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    min-width: 7rem;
    padding: 7px 9px;
  }

  &:first-child {
    border-left: 1px solid #e0e0e0;
  }
}

.builder-selector-component-material--selected {
  border-bottom: 1px solid white;
  color: black;
}

.builder-selector-component-colours {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 20px;
  margin-bottom: 8px;
}

.builder-selector-component-colour {
  flex: 0 0 auto;
  border-radius: 100px;
  display: inline-block;
  height: 32px;
  width: 32px;
  margin: 4px 16px 4px 0;
  cursor: pointer;
}

.builder-selector-component-colour--selected {
  background: black;

  .builder-selector-component-colour-inner {
    border: 1px solid white;
  }
}

.builder-selector-component-colour-inner {
  border-radius: 100px;
  display: inline-block;
  border: 1px solid #ccc;
  height: 30px;
  width: 30px;
  margin: 1px;
}

.builder-selector-component-colour--unavailable {
  .builder-selector-component-colour-inner {
    opacity: 0.6;
  }

  position: relative;

  &::before {
    content: ' ';
    display: block;
    position: absolute;
    top: 3px;
    right: 3px;
    left: 3px;
    bottom: 3px;
    background-image: linear-gradient(to bottom left, transparent 50%, #999 50%, #999 calc(50% + 1px), transparent 50%), linear-gradient(to bottom right, transparent 50%, #999 50%, #999 calc(50% + 1px), transparent 50%);
  }
}

.builder-reset-link {
  color: #ccc;
  font-size: 12px;
  cursor: default;
  text-decoration: underline;
}

.builder-reset-link--active {
  color: #969696;
  cursor: pointer;
}

.bundle-action-bar-savings {
  text-align: center;
  margin-bottom: 8px;
}

// Mobile-only styling for builder
@media only screen and (max-width: 798px) {
  .builder-preview-ar-button {
    top: auto;
    bottom: 0;
  }

  .builder-selector-component-sizes {
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .builder-selector-component-material {
    margin-bottom: 0;
    white-space: nowrap;
  }

  .builder-selector-component-colours {
    margin-left: -20px;
    margin-right: -20px;
    flex-wrap: nowrap;
    overflow-x: auto;
  }
}

.builder-selector-cm {
  position: relative;
  margin: 0 0 8px 0;
  @media only screen and (max-width: 798px) {
    margin-left: -20px;
    margin-right: -20px;
    border-bottom: none;
  }
  &__list {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    border-bottom: 1px solid #e0e0e0;
    overflow-x: scroll;
    overflow-y: hidden;
    //Hide scrollbars
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }

    @media only screen and (max-width: 798px) {
      border-bottom: none;
      &::after {
        display: block;
        width: 20px;
        content: '';
        flex-shrink: 0;
      }
    }
    li:first-child {
      @media only screen and (max-width: 798px) {
        margin-left: 20px;
      }
    }
  }

  &__arrow {
    border: none;
    appearance: none;
    background: none;
    box-shadow: none;
    outline: none;
    width: 4.5rem;
    height: calc(100% - 2px);
    position: absolute;
    top: 1px;
    padding: 0;
    margin: 0;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s 0.3s linear, opacity 0.3s ease-out;

    &.show {
      display: flex !important;
      opacity: 1;
      visibility: visible;
      transition: visibility 0s 0s linear, opacity 0.3s ease-out;
    }

    svg {
      pointer-events: none;
      width: 2rem;
      height: 2rem;
      fill: #bcaba0;
      display: block;
    }
    &--left {
      left: 0;
      background: rgb(255, 255, 255);
      background: linear-gradient(90deg, rgba(255, 255, 255, 1) 65%, rgba(255, 255, 255, 0) 100%);
      border-left: 1px solid #e0e0e0;

      svg {
        transform: translateX(-8px);
      }
    }
    &--right {
      right: 0;
      background: rgb(255, 255, 255);
      background: linear-gradient(-90deg, rgba(255, 255, 255, 1) 65%, rgba(255, 255, 255, 0) 100%);
      border-right: 1px solid #e0e0e0;

      svg {
        transform: translateX(8px);
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .page-builder #chat-button {
    bottom: 6rem;
  }

  .page-builder.loop-returns-activated #chat-button {
    bottom: #{(6rem + 6.4rem)};
  }

  .page-builder.show-dispatch-msg #chat-button {
    bottom: 10rem;
  }

  .page-builder.show-dispatch-msg.loop-returns-activated #chat-button {
    bottom: #{(10rem + 6.4rem)};
  }
}
