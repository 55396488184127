.wysiwyg a {
  text-decoration: underline !important;
}

.wysiwyg a:hover {
  color: #6a6a6a;
}

.wysiwyg h6 {
  margin-bottom: 12px;
}

.wysiwyg br {
  display: block;
  height: 12px;
  content: '';
}
.wysiwyg ul {
  margin-left: 24px;
}
.wysiwyg ul li {
  list-style: initial;
}

.wysiwyg ol {
  margin-left: 14px;
}
.wysiwyg ol li {
  list-style: number;
}

@media (max-width: 768px) {
  .wysiwyg h6 {
    margin-bottom: 16px;
  }
}
